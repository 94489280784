import { Head } from 'features/head'
import Help from 'features/help'
import { Suspense } from 'react'

export const HelpPage: React.FC = () => {
  return (
    <>
      <Head title='Help' />
      <Suspense fallback={<p>Loading...</p>}>
        <Help />
      </Suspense>
    </>
  )
}
