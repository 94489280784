import React, { useCallback, useState } from 'react'
import { Dialog, IconButton, ScrollArea } from '@radix-ui/themes'
import cn from 'classnames'
import { CrossIcon } from 'shared/icons'
import styles from './xyro-dialog.module.scss'

interface Props {
  children?: React.ReactNode
  dialogTrigger?: React.ReactNode
  className?: string
  // NOTE: This prop was added because scroll area doesn't work well with react-swiper lib, possibly remove in future
  scrollAreaSize?: '1' | '2' | '3'
  isCloseButtonEnabled?: boolean
  open?: boolean
  onOpenChange?: (isOpen: boolean) => void
}
export const XyroDialog: React.FC<Props> = ({
  onOpenChange = null,
  children = null,
  dialogTrigger = null,
  className = '',
  scrollAreaSize = '2',
  isCloseButtonEnabled = true,
  open = null
}) => {
  const [isUncontrolledDialogOpen, setIsUncontrolledDialoOpen] = useState(false)

  const isDialogOpen = open !== null ? open : isUncontrolledDialogOpen

  const handleOpenChange = useCallback(() => {
    if (onOpenChange !== null) {
      onOpenChange(!isDialogOpen)

      return
    }

    setIsUncontrolledDialoOpen(!isDialogOpen)
  }, [isDialogOpen, onOpenChange])

  return (
    <Dialog.Root
      open={isDialogOpen}
      onOpenChange={handleOpenChange}
    >
      {dialogTrigger ?
        <Dialog.Trigger>{dialogTrigger}</Dialog.Trigger>
      : null}

      <Dialog.Content
        className={cn(className, styles.contentContainer, {
          [styles.contentContainerOpenAnimation]: !isDialogOpen,
          [styles.contentContainerClosedAnimation]: isDialogOpen
        })}
      >
        {isCloseButtonEnabled ?
          <IconButton
            onClick={handleOpenChange}
            className={styles.closeButton}
          >
            <CrossIcon
              width={'3.5rem'}
              height={'3.5rem'}
              className='color-white'
            />
          </IconButton>
        : null}

        <ScrollArea
          className={styles.dialogScrollArea}
          size={scrollAreaSize}
          scrollbars='vertical'
          type='auto'
        >
          {children}
        </ScrollArea>
      </Dialog.Content>
    </Dialog.Root>
  )
}
