/* eslint-disable max-lines */
/* eslint-disable react/no-unescaped-entities */
import { Text } from '@radix-ui/themes'
import { Link } from 'react-router-dom'
import styles from './help.module.scss'

export const QUESTIONS = [
  {
    text: 'What is Phase 2 [Testnet], and when is the Mainnet planned?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          Phase 2 [Testnet] follows Phase 1 [Beta Testing] and precedes Phase 2
          [Mainnet]. During this phase, all game modes run on smart contracts.
          The Mainnet will launch once we ensure the XYRO platform operates
          smoothly and is bug-free on-chain.
        </Text>
      </>
    )
  },
  {
    text: 'When airdrop?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          The competitive airdrop campaign on Xyro is divided into three stages:
          alpha testing, beta testing, and the airdrop itself. The alpha and
          beta testing stages allow players to accumulate points, based on which
          the eventual airdrop amount will be calculated. The actual airdrop
          distribution will commence some time after the beta stage is finished.
          Stay tuned for announcements.
        </Text>
      </>
    )
  },

  {
    text: 'What is xyro.io?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          We&apos;re blending GameFi & SocialFi to revolutionize trading and
          prediction making, making crypto both accessible and attractive.
          xyro.io - the world&apos;s first Gamified Social trading platform,
          where each game mode represents an exhilarating journey into the realm
          of cryptocurrencies.
        </Text>
      </>
    )
  },
  {
    text: 'How was the idea of xyro.io born?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          The project was born as a result of more than 5 years of deep
          exploration within the Crypto market and community. The Founder
          noticed a common trend: people constantly predicting crypto prices,
          sharing setups, and engaging in discussions. With a vision to
          streamline this process, xyro.io emerged as a platform enabling
          seamless crypto market engagement, bypassing the complexities of
          trading mechanics and exchanges.
        </Text>
      </>
    )
  },
  {
    text: 'How can I register an account on XYRO?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          Registration is available only through crypto wallets. Currently, XYRO
          supports Metamask and Wallet Connect.
        </Text>
      </>
    )
  },
  {
    text: 'What game modes will be available during the Testnet?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          During the Testnet, and continuing into the Mainnet, four game modes
          will be available: Bull's Eye, Setups, Up/Down, and 1v1. Additional
          game modes are in development and will be introduced in the future.
        </Text>
      </>
    )
  },
  {
    text: 'Does xyro.io reward for bugs?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          Any bug that has an adverse impact on xyro.io or its users will be
          rewarded appropriately. This is limited to bugs with security or
          business consequences. Examples can include ability to access another
          user&apos;s account or ability to manipulate your balance. Please
          contact our support immediately if you&apos;d like to report a bug of
          this nature.
        </Text>
      </>
    )
  },
  {
    text: 'Where can I suggest a new idea?',
    answer: (
      <>
        <Text
          className={styles.answerItem}
          ml='2'
        >
          To suggest an idea to enhance xyro.io, use the&nbsp;
          <Link
            to={
              'https://discord.com/channels/1193903112956682271/1194251666522919002'
            }
            target='_blank'
          >
            💭・suggestions
          </Link>
          &nbsp; channel. The best suggestions will be rewarded with a&nbsp;
          <Link
            to={
              'https://discord.com/channels/1193903112956682271/1193917506113515681/1194913259304779776'
            }
            target='_blank'
          >
            @🧠 Gigabrain
          </Link>
          &nbsp;role.
        </Text>
      </>
    )
  }
]
