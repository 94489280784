import React from 'react'
import { Flex, Text } from '@radix-ui/themes'
import cn from 'classnames'
import styles from '../../table.module.scss'

interface Props {
  text: string | number
  isCentered?: boolean
  className?: string
}

export const TableItemAnyText: React.FC<Props> = ({
  text,
  isCentered = true,
  className = ''
}) => {
  return (
    <Flex
      height={'100%'}
      align={isCentered ? 'center' : 'start'}
      className={cn(styles.tableItemAnyText, className)}
    >
      <Text size={'3'}>{text}</Text>
    </Flex>
  )
}
