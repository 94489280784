import { useState } from 'react'
import { Maybe } from '__generated__/graphql'
import { useAccount, useWatchContractEvent } from 'wagmi'
import { Log } from 'viem'
import { addressList } from 'contracts/address'
import { abi } from '../abi'
import { wagmiWsConfig } from 'app/wagmi-config'

export const useBalanceEvents = () => {
  const [event, setEvent] = useState<Maybe<Log>>(null)
  const { address } = useAccount()

  useWatchContractEvent({
    config: wagmiWsConfig,
    address: addressList.usdc,
    abi,
    eventName: 'Transfer',
    poll: false,
    onLogs: logs => {
      if (logs.length === 0) return

      const { from, to } = logs[0].args

      if (from === address || to === address) {
        setEvent(logs[0])
      }
    }
  })

  return {
    event
  }
}
