import { WizardFlowType, WizardMode, WizardStep } from './types'

const { signIn, signUp } = WizardMode
const { none, web3Referral, web3Nft } = WizardFlowType
const { name, walletSelect, done, init, nftCheck, referral } = WizardStep

/**
 * @description Map of Auth steps
 * Inspect in the DrawIo Diagram: {@link file://./auth-flow.drawio}
 */
export const WIZARD_STEPS_BY_MODE_MAP: Record<
  WizardMode,
  Record<WizardFlowType, readonly WizardStep[] | null> | null
> = {
  [signUp]: {
    [none]: [init], // NOTE: initial steps
    [web3Referral]: [referral, walletSelect, name, done],
    [web3Nft]: [nftCheck, name, done],
  },
  [signIn]: {
    [none]: [init, done],
    [web3Referral]: null,
    [web3Nft]: null,
  }
} as const