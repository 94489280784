import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef
} from 'react'
import * as RadixForm from '@radix-ui/react-form'
import { Flex } from '@radix-ui/themes'
import styles from './amount-field.module.scss'

export const AmountValidationMessages = ({
  minAmount
}: {
  minAmount: number
}) => {
  return (
    <Flex
      gap='2'
      width={'auto'}
      wrap={'nowrap'}
    >
      <ValidationMessage match='valueMissing'>
        Enter your amount
      </ValidationMessage>
      <ValidationMessage match='typeMismatch'>
        Enter a valid amount
      </ValidationMessage>
      <ValidationMessage match='rangeUnderflow'>
        {'<'} {minAmount.toFixed(2)}
      </ValidationMessage>
      <ValidationMessage match='rangeOverflow'>
        Amount is too large
      </ValidationMessage>
      <ValidationMessage match='badInput'>Try another value</ValidationMessage>
    </Flex>
  )
}

interface MessageProps
  extends ComponentPropsWithoutRef<typeof RadixForm.Message> {
  children?: ReactNode
}
const ValidationMessage = forwardRef<
  ElementRef<typeof RadixForm.Message>,
  MessageProps
>(({ children, match, ...props }, ref) => {
  return (
    <RadixForm.Message
      {...props}
      match={match}
      ref={ref}
      className={styles.amountFieldErrorMessage}
    >
      {children}
    </RadixForm.Message>
  )
})
