import { useEffect, useRef } from 'react'
import { useReactiveVar } from '@apollo/client'
import { Card, Flex } from '@radix-ui/themes'
import classnames from 'classnames'
import { DataTestIDs } from 'shared/constants'
import { HideSidebarIcon } from 'shared/icons'
import { isChatOpenedVar } from 'shared/store/chat-state-store'
import { useGlobalRoomIdLoader } from '../hooks/use-global-room-id-loader'
import { globalChatMessagesVar } from '../store/chat-messages'
import { ChatFooter } from './chat-footer'
import { ChatHeader } from './chat-header'
import { ChatMessageList } from './chat-message-list'
import styles from '../chat.module.scss'

export const Chat: React.FC = () => {
  const messageListRef = useRef<HTMLDivElement | null>(null)

  const isChatOpened = useReactiveVar(isChatOpenedVar)

  useGlobalRoomIdLoader()

  useEffect(() => {
    return () => {
      globalChatMessagesVar([])
    }
  }, [])

  return (
    <Flex
      className={classnames(styles.chatWrapper, {
        [styles.opening]: isChatOpened
      })}
      position={'relative'}
      width={'100%'}
    >
      <Flex
        position={'absolute'}
        className={styles.chatCloseButton}
        onClick={() => isChatOpenedVar(false)}
        align={'center'}
        justify={'center'}
        data-testid={DataTestIDs.buttonGlobalChatClose}
      >
        <Flex
          align={'center'}
          justify={'center'}
          width={'100%'}
          height={'100%'}
          className={styles.chatCloseIconContainer}
        >
          <HideSidebarIcon
            color='var(--gray-9)'
            width={'3rem'}
            height={'3rem'}
          />
        </Flex>
      </Flex>

      <Card className={styles.chat}>
        <ChatHeader />

        <ChatMessageList messageListRef={messageListRef} />

        <ChatFooter messageListRef={messageListRef} />
      </Card>
    </Flex>
  )
}
