import { Suspense } from 'react'
import { Heading, Text } from '@radix-ui/themes'
import { Head } from 'features/head'

export const AboutPage: React.FC = () => {
  return (
    <>
      <Head title='About' />
      <Suspense fallback={<p>Loading...</p>}>
        <Heading
          as='h1'
          size='8'
          mt='6'
        >
          About our platform
        </Heading>

        <Text>
          Welcome to our Platform! Xyro is a gamified social trading platform
          where users can test their ability to predict cryptocurrency prices
          and win rewards, compete with others and with the platform, and share
          trading ideas without involvement of actual cryptocurrencies and/or
          fiat money. Platform offers a variety of game modes designed to
          challenge users prediction skills in different types of game that are
          available on the Platform.
        </Text>

        <Text>
          You must be at least 18 years old (or the age of majority in your
          jurisdiction) to access, use, or register with the Platform. We may
          suspend your access to the Platform if you are a resident or a citizen
          of the USA, EU, or other countries or territories under the sanctions
          of the United Nations or the European Union.
        </Text>

        <Text>
          We do not knowingly collect or maintain information from children
          under the age of 18 years old (or the age of majority in your
          jurisdiction). We collect information regarding your full name, date
          of birth, and crypto wallet address in the course of your registration
          and gaming experience on the Platform.
        </Text>

        <Text>
          We grant you a limited, non-exclusive, non-transferable,
          non-assignable license to access and make personal and non-commercial
          use of the downloadable software included in the Platform. You are not
          allowed to resell or commercially use any part of the Platform or its
          contents and materials. All content and materials provided on Xyro,
          including but not limited to logos, graphics, text, software, and any
          other assets, are our intellectual property and are protected by
          applicable copyright, trademark, and other intellectual property laws.
          You acknowledge and agree that you do not acquire any ownership rights
          to the content and materials provided on the Platform.
        </Text>

        <Text>
          We are not responsible for any actions, transactions, or interactions
          between you and other users on the Platform. We are not liable for any
          damages, losses, or injuries resulting from interactions with
          third-party websites, applications, or materials accessed through our
          Platform. We provide to you access to the Platform on an “as is” basis
          without warranties, and you engage with third parties and our Platform
          at your own risk. You acknowledge and accept these limitations of
          liability when accessing the Platform.
        </Text>

        {/* TODO: Add PrivacyPolicy content */}
      </Suspense>
    </>
  )
}
