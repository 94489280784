import {
  Button,
  Dialog,
  DialogContent,
  Flex,
  Heading,
  Link,
  Portal,
  Text
} from '@radix-ui/themes'
import { Overlay } from '@radix-ui/react-dialog'
import { XYRO_LINKS } from 'shared/constants'
import styles from './contact-us.module.scss'

export const ContactUsDialog = () => {
  const { telegram, telegramShort, discordInvite } = XYRO_LINKS

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button className={styles.contactUsButton}>CONTACT US</Button>
      </Dialog.Trigger>

      <Portal>
        <Overlay />
        <DialogContent className={styles.contactUsDialog}>
          <Dialog.Title asChild>
            <Heading
              align={'center'}
              weight={'medium'}
            >
              Contact us
            </Heading>
          </Dialog.Title>

          <Dialog.Description>
            <Flex
              gap='4'
              justify={'center'}
              direction={'column'}
              px='5'
            >
              <Text
                color='gray'
                align={'center'}
              >
                Please drop us a message and we&apos;ll respond as soon as
                possible
              </Text>
              <Text
                size={'5'}
                align={'center'}
              >
                <Flex
                  direction={'column'}
                  align={'center'}
                >
                  <ul className={styles.contactUsLinkList}>
                    <li>
                      <Link href={discordInvite}>{discordInvite}</Link> via
                      Discord
                    </li>
                    <li>
                      <Link href={telegram}>{telegramShort}</Link> via Telegram
                    </li>
                  </ul>
                </Flex>
              </Text>
            </Flex>
          </Dialog.Description>

          {/* // TODO: complete contact us form */}
          {/* <ContactUsForm /> */}
        </DialogContent>
      </Portal>
    </Dialog.Root>
  )
}
