import React, { FC } from 'react'
import { Text, Button, Flex, Heading } from '@radix-ui/themes'
import classnames from 'classnames'
import { RadixColorType, HeroBannerType } from 'shared/types'
import { XyroLink } from '../xyro-link'
import styles from './banner-slider.module.scss'
import { HERO_BANNERS } from './constants'

interface SlideProps {
  type: HeroBannerType
  handleClick?: () => void
  withButton?: boolean
}
export const BannerSlide: FC<SlideProps> = ({
  type,
  handleClick,
  withButton = true
}) => {
  const {
    cssClass,
    heading,
    text,
    callToAction,
    path,
    buttonColor,
    buttonText
  } = HERO_BANNERS?.[type]

  return (
    <Flex
      direction='column'
      justify='between'
      height='100%'
      width='100%'
      className={classnames(styles.bannerSlide, cssClass)}
      py={{ initial: '9', sm: '9' }}
      px={{ md: '6', initial: '4' }}
    >
      <Flex
        direction='column'
        className={styles.bannerSlideText}
      >
        <Heading
          className={styles.headingText}
          weight={'medium'}
          size={{ initial: '7', sm: '8' }}
          as='h2'
        >
          {heading}
          <br />
          <Text
            size={{ initial: '6', sm: '7' }}
            weight={'medium'}
            color='yellow'
          >
            {callToAction}
          </Text>
        </Heading>

        <Text
          className={classnames(styles.headingDescription, 'color-white')}
          weight={'medium'}
          size={{ initial: '2', sm: '3' }}
          mt={'4'}
        >
          {text}
        </Text>
      </Flex>

      {withButton && (
        <BannerSlideButon
          buttonText={buttonText}
          buttonColor={buttonColor}
          handleClick={handleClick}
          path={path}
        />
      )}
    </Flex>
  )
}

interface NavigateButtonProps {
  buttonColor?: RadixColorType
  handleClick?: () => void
  buttonText: string
}
const NavigateButton: React.FC<NavigateButtonProps> = ({
  buttonColor,
  handleClick,
  buttonText
}) => {
  return (
    <Button
      variant='outline'
      className={styles.bannerButton}
      color={buttonColor || 'sky'}
      mt={'8'}
      {...(handleClick ? { onClick: handleClick } : {})}
    >
      {buttonText}
    </Button>
  )
}

interface BannerSlideButonProps {
  path?: string
  buttonColor?: RadixColorType
  handleClick?: () => void
  buttonText: string
}
const BannerSlideButon: React.FC<BannerSlideButonProps> = ({
  path,
  buttonColor,
  handleClick,
  buttonText
}) => {
  return (
    <>
      {path ?
        <XyroLink to={path}>
          <Flex
            width={'100%'}
            height={'100%'}
          >
            <NavigateButton
              buttonColor={buttonColor}
              handleClick={handleClick}
              buttonText={buttonText}
            />
          </Flex>
        </XyroLink>
      : <NavigateButton
          buttonColor={buttonColor}
          handleClick={handleClick}
          buttonText={buttonText}
        />
      }
    </>
  )
}
