import { Flex } from '@radix-ui/themes'
import { DialogShared } from 'shared/ui'
import { isMintDialogOpenVar } from '../store/dialogs-balance-store'
import { MintDialogHeading } from './mint-dialog-heading'
import { MintDialogLink } from './mint-dialog-link'
import { MintForm } from './mint-form'

export const MintDialog = () => (
  <DialogShared
    width={'68.5rem'}
    openStore={isMintDialogOpenVar}
  >
    <Flex
      align={'center'}
      justify={'center'}
      direction={'column'}
    >
      <MintDialogHeading />

      <MintForm />

      <MintDialogLink />
    </Flex>
  </DialogShared>
)
