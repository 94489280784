import React from 'react'
import { Card } from '@radix-ui/themes'
import { HeroBannerType } from 'shared/types'
import { Autoplay, EffectFade, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { BannerSlide } from './banner-slide'
import styles from './banner-slider.module.scss'
import { AUTOPLAY_OPTIONS, PAGINATION_OPTIONS } from './constants'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

interface Props {
  banners: HeroBannerType[]
  handleClick?: () => void
  withButton?: boolean
}
export const BannerSlider: React.FC<Props> = ({
  banners,
  handleClick,
  withButton = true
}) => {
  return (
    <Card
      variant='ghost'
      className={styles.bannerSliderContainer}
    >
      <Swiper
        modules={[Pagination, EffectFade, Autoplay]}
        pagination={PAGINATION_OPTIONS}
        className={styles.bannerSlider}
        effect='fade'
        autoplay={AUTOPLAY_OPTIONS}
        loop
      >
        {banners.map(type => (
          <SwiperSlide key={type}>
            <BannerSlide
              type={type}
              handleClick={handleClick}
              withButton={withButton}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Card>
  )
}
