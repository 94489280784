export enum DataTestIDs {
  buttonLoginHeader = 'btnLoginHeader',
  buttonConnectWalletHeader = 'btnConnectWalletHeader',
  balanceValueHeader = 'balanceValueHeader',
  buttonRewardsHeader = 'headerRewards',
  buttonApprovedBalanceHeader = 'headerApprovedBalance',
  buttonGlobalChatHeader = 'headerOpenGlobalChat',
  buttonProfileHeader = 'headerProfileAvatar',
  buttonGlobalChatClose = 'btnHideGlobalChat',
  buttonGlobalChatSendMessage = 'sendMsgToGlobalChat',
  buttonProfileLink = 'btnProfile',
  buttonSettingsLink = 'btnSettings',
  buttonLogOut = 'btnLogOut',
  profileHeaderContent = 'profileHeaderContent',
  buttonConnectViaMM = 'connectViaMM',
  connectWalletModal = 'connectWalletModal',
  signUpModal = 'signUpModal',
  buttonUseRefferalCode = 'btnUseRefCode',
  refferalCodeInput = 'refCodeInput',
  buttonContinue = 'btnContinue',
  upDownSidePanel = 'upDownSidePanel',
  bullsEyeSidePanel = 'bullsEyeSidePanel',
  oneVsOneSidePanel = 'oneVsOneSidePanel',
  setupsSidePanel = 'setupsSidePanel',
  rewardsSidePanel = 'rewardsSidePanel',
  upDownGameStatus = 'upDownGameStatus',
  upDownAssetPrice = 'upDownAssetPrice',
  buttonBetUpUpDown = 'upDownBetUp',
  buttonBetDownUpDown = 'upDownBetDown',
  resultUpDownUpWins = 'resultUpWins',
  resultUpDownDownWins = 'resultDownWins',
  createNameInput = 'createNameInput',
  buttonSaveCreatedName = 'btnSaveCreatedName'
}
