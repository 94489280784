import { useCallback, useState } from 'react'
import * as Accordion from '@radix-ui/react-accordion'
import { MinusIcon, PlusIcon } from '@radix-ui/react-icons'
import { Card, Flex, Text } from '@radix-ui/themes'
import MobileStub from 'features/mobile-stub'
import { BannerSlider, ContactUs } from 'shared/ui'
import { DotTitle } from 'shared/ui/dot-title/dot-title'
import { QUESTIONS } from '../constanst'
import { AnswerButtons } from './answer-buttons'
import { AnswerLines } from './answer-line'
import styles from '../help.module.scss'

export const Help = () => {
  const [value, setValue] = useState<string>()
  const handleValueChange = useCallback((value: string) => {
    setValue(value)
  }, [])

  return (
    <MobileStub>
      <BannerSlider
        banners={['help']}
        withButton={false}
      />

      <Card size='4'>
        <DotTitle
          size='3'
          color='gray'
        >
          Here’s a few answers to our most common questions:
        </DotTitle>

        <Accordion.Root
          className={styles.helpAccordion}
          type='single'
          collapsible
          onValueChange={handleValueChange}
        >
          {QUESTIONS.map(q => (
            <Accordion.Item
              className={styles.helpItem}
              value={q.text}
              key={q.text}
            >
              <Accordion.Header
                className={styles.helpHeader}
                asChild
              >
                <Flex justify={'between'}>
                  <Accordion.Trigger className={styles.helpItemTrigger}>
                    <Text size='7'>{q.text}</Text>
                    {value === q.text ?
                      <MinusIcon
                        className={styles.icon}
                        aria-hidden
                      />
                    : <PlusIcon
                        className={styles.icon}
                        aria-hidden
                      />
                    }
                  </Accordion.Trigger>
                </Flex>
              </Accordion.Header>

              <Accordion.Content className={styles.helpContent}>
                <AnswerLines answer={q.answer} />
                <AnswerButtons question={q} />
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion.Root>
      </Card>

      <ContactUs />
    </MobileStub>
  )
}
