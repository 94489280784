import { ApolloError, useLazyQuery, useReactiveVar } from '@apollo/client'
import { GET_METAMASK_CHALLENGE } from 'api/auth/get-challenge'
import { notificationStateVar } from 'shared/store/notification'
import { notifyWithArbitraryErrorMessage } from 'shared/utils/notify-on-error'
import { useConnect, useDisconnect, useSignMessage } from 'wagmi'
import { CONNECTION_UNKWOWN_ERROR } from '../constants'
import { isSignInDialogOpenVar, isSignUpDialogOpenVar } from '../store/dialogs'
import {
  walletAddressVar,
  walletSignatureVar,
  wizardFlowTypeVar,
  wizardModeVar,
  wizardStepVar
} from '../store/wizard.store'
import { ConnectorProvider, WizardMode, WizardStep } from '../types'
import { getWizardNextStep } from '../utils/get-wizard-next-step'
import { notifyOnChallengeError } from '../utils/notify'
import { resetWizardState } from '../utils/reset-wizard-state'

// eslint-disable-next-line max-statements
export const useConnectWallet = () => {
  const wizardMode = useReactiveVar(wizardModeVar)
  const wizardFlowType = useReactiveVar(wizardFlowTypeVar)
  const wizardStep = useReactiveVar(wizardStepVar)

  const { connectAsync } = useConnect({
    mutation: {
      retry: (failureCount: number, error: Error) => {
        if (CONNECTION_UNKWOWN_ERROR.test(error?.message)) return true

        return false
      }
    }
  })
  const { disconnect } = useDisconnect()

  const { signMessageAsync } = useSignMessage()

  const [getWalletChallenge, { loading: queryLoading }] = useLazyQuery(
    GET_METAMASK_CHALLENGE
  )

  const handleConnection = async (provider: ConnectorProvider) => {
    if (!provider) return
    let errorMessage = 'Wallet was not connected'

    const account = await connectAsync({
      connector: provider.connector
    }).catch((error: any) => {
      if (error.details) {
        errorMessage = error.details
      }

      resetWizardState(wizardMode)
      disconnect()
    })

    const walletAddress = account?.accounts?.[0]

    if (!walletAddress) {
      notifyOnInfo(errorMessage)
    }

    return walletAddress
  }

  // eslint-disable-next-line max-statements
  const handleAuthWallet = async (provider: ConnectorProvider) => {
    const walletAddress = await handleConnection(provider)

    if (!walletAddress) return

    const queryVariables = {
      walletAddress
    }
    const { data } = await getWalletChallenge({
      variables: queryVariables,
      onError: (e: ApolloError) => {
        notifyOnChallengeError(e)
      }
    })
    if (!data?.getMetamaskChallenge) throw new Error('No challenge')

    const { challenge, walletHasAnyNft } = data?.getMetamaskChallenge

    const signature = await signMessageAsync({ message: challenge }).catch(
      (e: ApolloError) => {
        notifyOnError(e.message)
        disconnect()
      }
    )

    if (!(signature && walletAddress)) {
      notifyWithArbitraryErrorMessage(
        'Cannot connect to your wallet. Please, retry'
      )
      return
    }

    walletSignatureVar(signature)
    walletAddressVar(walletAddress)

    const nextStep = getWizardNextStep(
      wizardMode,
      wizardFlowType,
      wizardStep,
      walletHasAnyNft
    )

    wizardStepVar(nextStep || WizardStep.init)

    if (wizardMode === WizardMode.signUp) {
      isSignUpDialogOpenVar(true)
    } else {
      isSignInDialogOpenVar(true)
    }
  }

  const handleConnectWallet = (provider: ConnectorProvider) => {
    handleConnection(provider)
  }

  return {
    handleAuthWallet,
    handleConnectWallet,
    loading: queryLoading
  }
}

const notifyOnInfo = (message: string) => {
  notificationStateVar({
    isOpen: true,
    type: 'info',
    title: 'Info',
    description: message
  })
}

const notifyOnError = (message: string) => {
  notificationStateVar({
    isOpen: true,
    type: 'error',
    title: 'Error!',
    description: message
  })
}
