import { Head } from 'features/head'
import { Suspense, lazy } from 'react'

const UserSettings = lazy(() => import('features/user-settings'))

export const UserSettingsPage: React.FC = (): JSX.Element => {
  return (
    <>
      <Head title='Settings' />
      <Suspense fallback={<p>Loading...</p>}>
        <UserSettings />
      </Suspense>
    </>
  )
}
