import { Table, Flex, Button } from '@radix-ui/themes'
import styles from '../../table.module.scss'

export const OpenDetailsCell = ({ onClick }: { onClick?: () => void }) => {
  return (
    <Table.Cell className={styles.detailsCell}>
      <Flex
        align={'center'}
        height={'100%'}
      >
        <Button
          variant='ghost'
          onClick={onClick}
          m={'0'}
          mt={'-3'}
        >
          Open details
        </Button>
      </Flex>
    </Table.Cell>
  )
}
